<template>
  <div style="padding: 0 20px">
    <a-tabs default-active-key="1" size="large">
      <a-tab-pane key="1">
        <span slot="tab" v-html="tabName"> </span>
        <a-spin :spinning="loading" style="padding: 20px">
          <a-list item-layout="horizontal" :data-source="list.records">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-list-item-meta>
                <a
                  slot="title"
                  href="javascript:void(0)"
                  @click="shownewsdetail(item.id)"
                  style="font-size: 1rem; font-weight: bold"
                  >{{ item.title }}</a
                >
                <template slot="description">
                  <span>发布时间：{{ item.publishTime }}</span>
                  <span style="margin-left: 16px" v-if="isZgyd">
                    发布人：{{ item.userName }}
                  </span>
                  <span style="margin-left: 16px">
                    浏览次数：{{ item.pageViews }}
                  </span>
                  <span style="margin-left: 16px" v-if="isZgyd">
                    评论数：{{ item.commentCount }}
                  </span>
                </template>
              </a-list-item-meta>
            </a-list-item>
          </a-list>

          <a-pagination
            size="small"
            :pageSize="20"
            @change="pagechanged"
            style="margin-top: 20px; text-align: center"
            :total="list.total"
            :show-total="(total) => `总数 ${list.total} 条`"
          />
          <a-modal
            title="详情"
            v-model="newsdetailmodal.visible"
            width="70%"
            :dialog-style="{ top: '10px' }"
            :footer="false"
            destroyOnClose
            :maskClosable="false"
          >
            <newsdetail
              @callback="closenewsmodal"
              :isZgyd="isZgyd"
              :id="newsdetailmodal.id"
            />
          </a-modal>
        </a-spin>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import newsdetail from "./newsdetail.vue";
import newsApi from "@/api/cms/newsArticle";
import commentApi from "@/api/comment";
export default {
  name: "newslist",

  data() {
    return {
      loading: false,
      list: {
        total: 0,
        current: 1,
        records: [],
      },
      newsdetailmodal: {
        visible: false,
        id: 0,
      },
      categoryName: "",
      isZgyd: false,
      title: "",
      tabName: "",
    };
  },
  components: { newsdetail },
  mounted() {
    this.categoryName = this.$route.query.categoryName;
    this.isZgyd = this.$route.query.isZgyd;
    this.title = this.$route.query.title;
    this.tabName =
      this.categoryName && this.categoryName != ""
        ? this.categoryName
        : '<span style="color:#f5222d">' + this.title + "</span>的检索结果";
    this.getList();
  },
  methods: {
    pagechanged(page) {
      this.list.current = page;
      this.getList();
    },
    closenewsmodal() {
      this.newsdetailmodal.visible = false;
    },
    shownewsdetail(id) {
      this.newsdetailmodal.id = id;
      this.newsdetailmodal.visible = true;
    },

    getList() {
      this.loading = true;
      newsApi
        .pageListForFront(this.list.current, 20, {
          categoryName: this.categoryName,
          title: this.title,
        })
        .then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            this.list.total = Number(res.data.total);
            res.data.records.forEach((item) => {
              item.commentCount = 0;
            });
            this.list.records = res.data.records;
            if (this.isZgyd) {
              this.getCommentCount(res.data.records.map((item) => item.id));
            }
          }
        });
    },
    getCommentCount(guids) {
      commentApi.getCountByGuid(guids, 1).then((res) => {
        this.list.records.forEach((item) => {
          let fin = res.data.find((r) => item.id == r.guid);
          if (fin) {
            item.commentCount = fin.totalCount;
          }
        });
      });
    },
  },
};
</script>
