<template>
  <div>
    <h1 style="text-align: center" v-show="titleShow">{{ detail.title }}</h1>
    <div style="margin: 0 30px 30px; color: #888888">
      <span>发布时间：{{ detail.publishTime }}</span
      ><span v-if="isZgyd" style="margin-left: 8px"
        >发布人：{{ detail.userName }}</span
      >
    </div>
    <div
      v-html="detail.content"
      style="margin-left: 30px; margin-right: 30px"
    ></div>
    <div style="margin: 30px" v-if="isZgyd">
      <a-comment>
        <a-avatar
          slot="avatar"
          size="small"
          :style="{
            verticalAlign: 'middle',
            backgroundColor: '#1890ff',
          }"
          icon="user"
        />
        <div slot="content">
          <a-form-item>
            <a-textarea :rows="4" v-model="commentValue" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="addComment"> 发表 </a-button>
          </a-form-item>
        </div>
      </a-comment>
      <a-list
        :header="`${pagination.total} 条评论`"
        item-layout="horizontal"
        :data-source="commentDatas"
        :pagination="pagination"
      >
        <a-list-item slot="renderItem" slot-scope="item">
          <a-comment :author="item.creatorName" style="width: 100%">
            <a-avatar
              slot="avatar"
              size="small"
              :style="{
                verticalAlign: 'middle',
                backgroundColor: '#1890ff',
              }"
              >{{ item.avatar }}</a-avatar
            >
            <a-tooltip
              slot="datetime"
              :title="
                item.addTime ? item.addTime.format('YYYY-MM-DD HH:mm:ss') : ''
              "
            >
              <span>{{ item.addTime ? item.addTime.fromNow() : "" }}</span>
            </a-tooltip>
            <p slot="content">
              {{ item.comment }}
            </p>
            <template slot="actions" v-if="item.creatorUser == getUserName()">
              <a-popconfirm
                title="是否确认删除评论?"
                @confirm="deleteComment(item.id)"
              >
                <span>删除</span>
              </a-popconfirm>
            </template>
          </a-comment>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>
<script>
import config from "@/config";
import newsApi from "@/api/cms/newsArticle";
import commentApi from "@/api/comment";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "newsdetail",
  props: {
    id: Number,
    isZgyd: Boolean,
  },
  data() {
    return {
      detail: {},
      titleShow: false,
      loading: false,
      commentValue: "", // 评论内容
      pagination: {
        onChange: (page) => {
          this.pagination.current = page;
          this.getCommentList();
        },
        current: 1,
        pageSize: 10,
        total: 0,
      },
      commentDatas: [],
    };
  },
  mounted() {
    this.getDetail();
    this.getCommentList();
  },
  methods: {
    ...mapGetters({
      getUserName: "currentUser/getUserName",
    }),
    async getDetail() {
      var res = await newsApi.getById(this.id, 1);
      if (res.errorCode === this.$commons.RespCode.success) {
        try {
          // var htmlparser = new DOMParser();
          // var doc = htmlparser.parseFromString(res.data.content, "text/html");
          // var iframe = doc.getElementsByTagName("iframe");
          // this.titleShow = (iframe.length > 0);
          var htmlparser = new DOMParser();
          var doc = htmlparser.parseFromString(res.data.content, "text/html");
          var alist = doc.getElementsByTagName("a");
          if (alist.length > 0) {
            var content = "";
            for (var i = 0; i < alist.length; i++) {
              var href = alist[i].getAttribute("href");
              if (
                href.toLowerCase().indexOf(".doc") > 0 ||
                href.toLowerCase().indexOf(".docx") > 0
              ) {
                href =
                  "https://view.officeapps.live.com/op/view.aspx?src=" +
                  href +
                  "&wdOrigin=BROWSELINK";
              } else {
                href =
                  config.baseUrl +
                  "/oa/res/filePreview?appKey=oa&fileUrl=" +
                  encodeURIComponent(href) +
                  "&title=" +
                  res.data.title +
                  ".pdf";
              }
              content +=
                "<iframe src='" +
                href +
                "' width='100%' height='600' frameborder='0' download='" +
                res.data.title +
                ".pdf'></iframe><br/>";
            }
            res.data.content = content;
          }
          var iframe = doc.getElementsByTagName("iframe");
          if (iframe.length > 0) {
            for (var i = 0; i < iframe.length; i++) {
              var href = iframe[i].getAttribute("src");
              if (
                href.toLowerCase().indexOf(".doc") > 0 ||
                href.toLowerCase().indexOf(".docx") > 0
              ) {
                href =
                  "https://view.officeapps.live.com/op/view.aspx?src=" +
                  href +
                  "&wdOrigin=BROWSELINK";
              } else {
                href =
                  config.baseUrl +
                  "/oa/res/filePreview?appKey=oa&fileUrl=" +
                  encodeURIComponent(href) +
                  "&title=" +
                  res.data.title +
                  ".pdf";
              }
              iframe[i].src = href;
            }
            res.data.content = doc.documentElement.outerHTML;
          }
        } catch (e) {}
        this.detail = res.data;
      } else {
        this.$message.error(res.errorMsg);
      }
    },
    onReply(comment) {
      comment.actions = comment.reply ? ["回复"] : ["取消回复"];
      comment.reply = !comment.reply;
    },
    addComment() {
      if (this.commentValue == "") {
        this.$message.error("请输入评论内容");
        return;
      }
      commentApi
        .add({
          guid: this.id,
          comment: this.commentValue,
          source: 1,
          status: 1,
          auditStatus: 1,
        })
        .then((res) => {
          if (res.errorCode === this.$commons.RespCode.success) {
            this.commentValue = "";
            this.getCommentList();
            this.$message.success("评论成功");
          } else {
            this.$message.error(res.errorMsg);
          }
        });
    },
    getCommentList() {
      this.loading = true;
      commentApi
        .getListForContent({
          pageNow: this.pagination.current,
          pageSize: this.pagination.pageSize,
          guid: this.id,
        })
        .then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            this.pagination.total = Number(res.data.total);
            res.data.records.forEach((item) => {
              item.avatar = item.creatorName.substring(0, 1);
              item.addTime = moment(item.addTime, "YYYY-MM-DD HH:mm:ss");
              item.reply = false;
            });
            this.commentDatas = res.data.records;
          }
        });
    },
    deleteComment(id) {
      commentApi.deleteComment(id).then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getCommentList();
          this.$message.success("删除成功");
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
  },
};
</script>
<style>
p {
  width: 100%;
}
img {
  max-width: 100%;
  height: auto;
}
</style>
