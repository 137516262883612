import request from "@/request/index";
const part_url = "/oa/comment/";

export default {
    add(params) {
        let url = part_url + "add";
        return request.post(url, {}, params);
    },
    deleteComment(id) {
        let url = part_url + "delete";
        return request.get(url, { id }, {});
    },
    getListForContent(params) {
        let url = part_url + "getPageListForContent";
        return request.get(url, params, {});
    },
    getCountByGuid(guids, source) {
        let url = part_url + "getCountByGuid";
        return request.post(url, { source }, guids);
    },
    pageList(pageNow, pageSize, params) {
        let url = part_url + "getPageList";
        return request.get(url, { pageNow, pageSize, ...params }, {});
    },
}